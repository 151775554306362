import * as type from 'constants/action_types'
import * as accountActionType from 'constants/action_types/accounts'

import { getSelectedAmazonSellerAccount } from 'helpers/amazon_seller_account'
import { persistGlobalData } from 'helpers/storage'

import Api from 'services/api'
import { updatePendo, togglePendoGuides, isPendoReady } from 'services/pendo'
import { clearSegmentTraits, sendSegmentIdentity } from 'services/segment'
import { setUserInGoogleAnalytics } from 'services/google_data_layer'

import { changeLanguage } from 'services/i18n'
import { filterGlobalData } from 'helpers/account/users'
import { setGetFeedbackCustomVariables } from 'services/getFeedback'
import { setSentryMWSData, setSentryUserData } from '../config/sentry'

import { createNotification } from './notifications'
import { UNLIMITED_ACCESS_LIMIT } from 'constants/feature_limits'

/*
 *               _
 *              | |
 *           ___| |_ ___  _ __
 *          / __| __/ _ \| '_ \
 *          \__ \ || (_) | |_) |
 *          |___/\__\___/| .__/
 *                       | |
 *                       |_|
 *
 * Please do not add to this file.
 * Additions to this file will get pulled into the main JS bundle where they probably don't need to be
 * Please create a new file, add your methods/constants to that and import just the code you need.
 *
 * For more info see https://github.com/Junglescout/docs/blob/master/client/Code-Splitting.md
 */

export const updateLanguage = language => (dispatch, getState) => {
  dispatch({
    type: accountActionType.ACCOUNT_SET_LANGUAGE,
    payload: {
      language
    }
  })

  // Don't care about api res
  Api.updateLanguage(language)

  persistGlobalData(getState())
}

// Load global data for tracked products and user membership information
export const loadGlobalData = () => async (dispatch, getState) => {
  const state = getState()
  const res = await Api.loadGlobalData()

  if (res.ok) {
    const {
      pendo,
      user,
      user: { selected_language },
      segment
    } = res.data
    const {
      sessions: { locationHistory }
    } = getState()

    const filteredData = filterGlobalData(res.data)

    dispatch({
      type: type.LOAD_GLOBAL_DATA,
      payload: filteredData
    })

    setUserInGoogleAnalytics(res.data)

    clearSegmentTraits()
    sendSegmentIdentity(user.id, {
      email: user.email,
      languageApp: segment?.selectedLanguage
    })
    setGetFeedbackCustomVariables(segment)
    setSentryUserData(user)

    const {
      amazon_seller_account_id,
      marketplace_id
    } = getSelectedAmazonSellerAccount(state)
    setSentryMWSData({ amazon_seller_account_id, marketplace_id })

    // Set default tracker range
    const { maximumSelectedDays } = res.data.featureLimits.productTracker
    const daysToMonthsMap = {
      30: 1,
      90: 3,
      180: 6,
      [UNLIMITED_ACCESS_LIMIT]: 6
    }

    const selectedMonths = daysToMonthsMap[maximumSelectedDays] || 1

    dispatch({
      type: type.SELECT_DATE_RANGE,
      payload: {
        value: selectedMonths
      }
    })
    persistGlobalData(getState())

    togglePendoGuides(locationHistory)
    dispatch(updatePendoData(pendo))

    if (selected_language !== 'en-US') {
      changeLanguage(selected_language)
    }
  }

  dispatch({
    type: res.ok ? type.COMPLETED_GLOBAL_ACTION : type.RESET_GLOBAL_ACTION,
    payload: {
      key: 'initialLoad',
      success: res.ok
    }
  })
}

export const setGlobalModal = id => dispatch => {
  dispatch({
    type: type.SET_GLOBAL_MODAL,
    payload: typeof id === 'string' ? id : null
  })
}

export const setGlobalModalMetadata = metadata => dispatch => {
  dispatch({
    type: type.SET_GLOBAL_MODAL_METADATA,
    payload: metadata
  })
}

export const fetchSellerPlans = () => async dispatch => {
  dispatch({
    type: type.PENDING_GLOBAL_ACTION,
    payload: 'sellerPlans'
  })

  const res = await Api.fetchSellerPlans()

  if (res.ok) {
    dispatch({
      type: type.SET_SELLER_PLANS,
      payload: {
        data: res.data
      }
    })
  } else {
    const notification = createNotification({
      message: res.error,
      level: 'error',
      title: 'Seller Plans Error'
    })
    dispatch(notification)
  }

  dispatch({
    type: type.RESET_GLOBAL_ACTION,
    payload: 'sellerPlans'
  })
}

export const toggleProfitCalculator = (
  show,
  product = null
) => async dispatch => {
  dispatch({
    type: type.TOGGLE_PROFIT_CALCULATOR,
    payload: {
      show,
      product
    }
  })
}

export const toggleVariantModal = () => dispatch => {
  dispatch({
    type: type.TOGGLE_VARIANT_MODAL
  })
}

export const addLocationHistory = path => (dispatch, getState) => {
  const {
    globalData: { isImpersonating },
    sessions: { locationHistory }
  } = getState()

  if (locationHistory !== path) {
    dispatch({
      type: type.COMPLETED_ADD_LOCATION_HISTORY,
      payload: path
    })

    if (!path.match(/admin/) && !isImpersonating) {
      const _sift = window._sift || []
      window._sift = _sift
      _sift.push(['_trackPageview'])
    }

    togglePendoGuides(path)
  }
}

export const updatePendoData = ({ visitor, account }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: type.UPDATE_PENDO,
    payload: {
      visitor,
      account
    }
  })

  // Pendo is now being injected by Segment, fallback in case it is not
  //  loaded before global data is ready
  if (isPendoReady()) {
    updatePendo(getState().globalData.pendo)
  } else {
    setTimeout(() => updatePendo(getState().globalData.pendo), 5000)
  }
}

export const selectAmazonSellerAccount = ({ account, country }) => dispatch => {
  dispatch({
    type: type.SELECT_AMAZON_SELLER_ACCOUNT,
    payload: { account, country }
  })

  setSentryMWSData({
    amazon_seller_account_id: account?.id,
    marketplace_id: country
  })
}

export const updateActivePpcAccounts = activePPCAccounts => dispatch => {
  dispatch(updatePendoData({ account: { activePPCAccounts } }))
}
